/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
	    $('.follow_us_trigger').click(function(){
		   if($('.social_box .icons').hasClass('active')){
		   	$('.social_box .icons').removeClass('active'); 
		   }else{
		   	$('.social_box .icons').addClass('active'); 
		   }
	    });
	    
		$(window).load(function() {
		  $('.flexslider').flexslider({
		    animation: "fade",
		    directionNav: false
		  });
		  
		  
		  $('.flex-prev').on('click', function(){
		  	$('.flexslider').flexslider('prev');
		  	return false;
		  });
		
		  $('.flex-next').on('click', function(){
		  	$('.flexslider').flexslider('next');
		  	return false;
		  });
		  
		  $('.flexslider_distribuons').flexslider({
		    animation: "slide",
	        animationLoop: false,
	        controlNav: false,
	        directionNav: false,
	        itemWidth: 120,
	        itemMargin: 5,
	        minItems: 1,
	        maxItems: 1,
		  });
		  
		  $('.flex-prev-distribuons').on('click', function(){
		  	$('.flexslider_distribuons').flexslider('prev');
		  	return false;
		  });
		
		  $('.flex-next-distribuons').on('click', function(){
		  	$('.flexslider_distribuons').flexslider('next');
		  	return false;
		  });
		  
		});
		
		$(function() {
		   $( ".filter > a" ).click(function(){
			   var this_cat = jQuery(this).attr('cat');
			   jQuery('.filter_content > div').removeClass('active');
			   jQuery('.distribuons a').removeClass('active');
			   jQuery('.content_' + this_cat).addClass('active');
			   jQuery(this).addClass('active');
		   });
		   
		    $( ".distri_icon a" ).click(function(){
			   var this_cat = jQuery(this).attr('cat');
			   jQuery('.filter_content > div').removeClass('active');
			   jQuery('.distribuons a').removeClass('active');
			   jQuery('.content_' + this_cat).addClass('active');
			   jQuery(this).addClass('active');
		   });
		});
		
		$('.show_all_map').on('click', function(){
		  	if($('.contact_box').hasClass('active')){
		   		$('.contact_box').removeClass('active'); 
		   		$(this).find('i').removeClass('icon-thin-arrow-right').addClass('icon-thin-arrow-left'); 
		  	}else{
		   		$('.contact_box').addClass('active'); 
		   		$(this).find('i').removeClass('icon-thin-arrow-left').addClass('icon-thin-arrow-right'); 
		   	}
		});
		
		$('.gotop').click(function(){
			$("html, body").animate({ scrollTop: 0 }, "slow");
			return false;
		});

		var slider_height = 0;
		var menu_height = 0;
		var distribuons_height = 0;
		var nousjoindre_height = 0;
		
		$(window).on("load resize", function(){
			slider_height = jQuery('.flexslider').outerHeight();
			menu_height = jQuery('.banner').outerHeight();
			distribuons_height = jQuery('.distribuons').outerHeight();
			nousjoindre_height = jQuery('.nous_joindre').outerHeight();
			
						
			$('.contact').click(function(event){
				//$('body').css('overflow', 'hidden');
				event.preventDefault();
				$("body, html").animate({ scrollTop: $('.nous_joindre').offset().top - menu_height + 10 }, 1000, function(){
					$('body').css('overflow', 'auto');
				});
				return false;
			});
			
			$('.our_products').click(function(event){
				//$('body').css('overflow', 'hidden');
				event.preventDefault();
				$("body, html").animate({ scrollTop: $('.distribuons').offset().top - menu_height }, 1000, function(){
					$('body').css('overflow', 'auto');
				});
				return false;
			});
			
			
		});
		
		
		$(window).scroll(function(){
			if($(this).scrollTop() > (slider_height - menu_height - 50)){
				$('.banner').addClass('full');
			}else{
				$('.banner').removeClass('full');
			}
				
			if($(this).scrollTop() > (slider_height - menu_height - 100)){
				$('.menu_active').removeClass('menu_active');
				$('.our_products').addClass('menu_active');
			}
			
			if($(this).scrollTop() > (slider_height + distribuons_height - menu_height - 10)){
				$('.menu_active').removeClass('menu_active');
				$('.contact').addClass('menu_active');
			}
			
			if($(this).scrollTop() < (slider_height - menu_height)){
				$('.menu_active').removeClass('menu_active');
			}

		});
		
		
		
		

		
		
			    
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
